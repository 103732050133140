import React from 'react';

import Layout from '../../components/Layout'
import deck from '../../images/deck.png'

export default () => {

  return (
    <Layout>
      <div style={{textAlign: 'center', paddingTop: '90px'}}>
      <img 
      src={deck} alt="DotSlashFrag January 2022 Pitch Deck" 
      style={{ display: 'block', maxWidth: '600px', minWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }} 
      ></img>
      </div>
     
    </Layout>
);
};